export const serverUrl = {
  u: 'https://test.alistetechnologies.com:9443',
  aws: 'https://web.alistetechnologies.com',
  t: 'https://subscriptioncloud.alistetechnologies.com',
  local: 'http://localhost:3000',
  s: 'https://int.alistetechnologies.com',
  pay: 'https://web.alistetechnologies.com/payments/',
  partialPay: '/payments/partials/',
  partialPayZoho: '/payments/partial/',
  amazon: 'https://8fza561rd2.execute-api.ap-south-1.amazonaws.com/default',
  analystic: 'https://analytics.alistetechnologies.com:443',
  stock: 'https://wtro4ntaa8.execute-api.ap-south-1.amazonaws.com/default',
  smartMeter: 'https://smartmeter.aliste.io',
  test: 'https://test.alistetechnologies.com:443',
  user_service: 'https://services.user.aliste.io',
  sub: 'https://subscriptioncloud.alistetechnologies.com',
  web: 'https://web.alistetechnologies.com',
  localhost: 'http://localhost:3000',
  smTest: 'https://test.alistetechnologies.com:443',
};

export const pages = {
  addressLayout: 'address&layout',
  installation: 'installationPage',
  department: 'Department',
  grivianceDeartment: 'grivianceDeartment',
  elctrician: 'elctrician',
  search: 'search',
};

export const grevianceElectrician = ['1016', '1043'];

export const MovementTransactionsType = {
  Stock: 0,
  DispatchedToSite: 1,
  Electrician: 2,
  Exchanged: 3,
  Installed: 4,
  Deleted: 5,
  Returned: 6,
  Other: 7,
  Restocked: 8,
};
